#accordion {
  margin-top: 20px;
}

.tab {
  border: 0.1px solid;
  border-color: gray;
}

.title {
  padding: 10px;
  background: #bdb5b55c;
  font-weight: bold;
  font-size: 15px;
  position: sticky;
}
.content {
  padding: 10px;
  border: 1px 0 1px 1px;
  border-style: solid;
  border-color: #f4f4f4;
}

.flex {
  display: flex;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.jc-btwn {
  align-items: center;
  justify-content: space-between;
}

.plus {
  font-size: 30px;
}
.minus {
  font-size: 30px;
}

.tab .content.show .tab .title {
  font-size: 14px;
  background-color: #f4f4f4 !important;

  font-weight: bold;
}
.content.show {
  background: #ebf0f5;
}
