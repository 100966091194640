.tb-bar .MuiTabs-flexContainer {
    display: grid!important;
    grid-template-rows: auto!important;
    grid-template-columns: repeat(3, 1fr)!important;

}
.tb-bar span.MuiTabs-indicator {
    /* display: none!important; */
}
.tb-bar span.MuiTouchRipple-root {
    border-bottom: 2px solid #ebebeb;
}
.tb-bar a.MuiTab-root{
    /* text-decoration: underline;
    text-underline-position: under;
    text-decoration-thickness: 2px; */
}

/* .Mui-selected  */