label#password-label {
    transform: translate(14px, -9px) scale(0.75);
    padding: 0 4px;
    background: #fff;
}


/* 404 error  component Page */

.error-page {
    text-align: center;
    z-index: 1200;
    background: #ebf0f5!important;
    position: absolute; top: 0; right: 0; bottom: 0; left: 0;
  }
  
  .error-header {
    
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #111;
  }
  .error-header h1, .error-header h3, .error-header p {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .error-header h1 {
  font-size: 6em;
  }
  
  
  @media (max-width: 380px) {
  .brand-login {
    padding-bottom: 8rem!important;
}.tdc-home .classes .wrapper {
    padding: 1rem 0rem!important;
}
  }
  
